@import url('https://fonts.googleapis.com/css?family=EB+Garamond:400,500,600');
/* noto-serif-jp-regular - japanese_latin */
@font-face {
  font-family: "Noto Serif JP";
  font-style: normal;
  font-weight: 400;
  src: url('//hayhutte.twotone.jp/wp/wp-content/themes/hayhutte/_assets/font/noto-serif-jp-v3-japanese_latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Noto Serif JP'), local('NotoSerifJP-Regular'),
       url('//hayhutte.twotone.jp/wp/wp-content/themes/hayhutte/_assets/font/noto-serif-jp-v3-japanese_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('//hayhutte.twotone.jp/wp/wp-content/themes/hayhutte/_assets/font/noto-serif-jp-v3-japanese_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('//hayhutte.twotone.jp/wp/wp-content/themes/hayhutte/_assets/font/noto-serif-jp-v3-japanese_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('//hayhutte.twotone.jp/wp/wp-content/themes/hayhutte/_assets/font/noto-serif-jp-v3-japanese_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('//hayhutte.twotone.jp/wp/wp-content/themes/hayhutte/_assets/font/noto-serif-jp-v3-japanese_latin-regular.svg#NotoSerifJP') format('svg'); /* Legacy iOS */
}

*, *:before, *:after{
  box-sizing: border-box;
}

::selection {
  background: rgba($yellow, 0.99);
  color: #000;
}

body{
  font-family: "Noto Serif JP", serif;
  letter-spacing: 0.032em;
  background-color: #e6e6e6;//$yellow;
  &.active{
    @include sp{
      width: 100%;
      overflow: hidden;
    }
  }
}

a{
  color: inherit;
  text-decoration: none;
}
